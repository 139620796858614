* {
  margin: 0;
  padding: 0;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.demo-logo-vertical {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 6px;
}

.m12r {
  margin: 2rem 1rem;
}

.mainContainer {
  padding: 1rem 2rem;
}

.mainTitle {
  padding: 0 0 2rem;
}

.ant-layout-sider-light {
  background-color: #f0f0f8;
}

:where(.css-dev-only-do-not-override-1wazalj).ant-menu-light
  .ant-menu-item-selected,
:where(.css-dev-only-do-not-override-1wazalj).ant-menu-light
  > .ant-menu
  .ant-menu-item-selected {
  background-color: #5b7690;
  color: white;
}

.ant-menu-item-selected.ant-menu-item-only-child {
  border-radius: 0;
}

/* login css start */
.loginTitle {
  color: #5b7690;
  font-size: 24px;
  font-family: Arial;
  font-weight: 700;
  letter-spacing: 0.48px;
}
.loginTitleDiv {
  text-align: center;
}
.loginSubTitle {
  color: #525252;
  font-size: 12px;
  font-family: Arial;
  letter-spacing: 0.24px;
}
.loginFormContainer {
  padding: 5rem;
}
.loginForm {
  margin-top: 60px;
}
.remenberAndFrgotPassContainer {
  display: flex;
  justify-content: space-between;
}

.ant-form-item-label > label {
  color: #525252 !important;
  font-size: 12px !important;
  font-family: Arial;
  letter-spacing: 0.24px;
}
.myAntIpt {
  border-radius: 0 !important;
  background: #f5f6fa !important;
  padding: 10px !important;
  line-height: 0 !important;
}
.myAntIpt2 {
  border-radius: 0 !important;
  background: #f5f6fa !important;
  padding: 5px 10px !important;
  line-height: 0 !important;
  width: 100%;
}
.myAntLoginBtn {
  border-radius: 0 !important;
  height: 35px !important;
}
.otpVerifyLabel {
  color: #525252;
  font-size: 12px;
  font-family: Arial;
  letter-spacing: 0.24px;
}

.otpVerifyIpt {
  width: 50px;
}

.OTPInputContainer input {
  height: 45px;
  width: 45px !important;
  margin: 30px;
}

.OtpResendText {
  color: #525252;
  font-size: 12px;
  font-family: Arial;
  letter-spacing: 0.24px;
  text-align: center;
}

/* login css end */

/* Layout Start */
/* aside.ant-layout-sider.ant-layout-sider-light {
  width: 250px !important;
  min-width: 250px !important;
  max-width: 250px !important;
  flex: 0 0 250px !important;
} */
.menuText {
  font-size: 14px;
  font-family: Arial;
  letter-spacing: 0.28px;
}

:where(.css-dev-only-do-not-override-1wazalj).ant-menu-light,
:where(.css-dev-only-do-not-override-1wazalj).ant-menu-light > .ant-menu {
  color: #525252;
}
:where(.css-dev-only-do-not-override-1wazalj).ant-layout
  .ant-layout-sider-light {
  background: #f5f6fa;
}
:where(.css-dev-only-do-not-override-1wazalj).ant-menu-light,
:where(.css-dev-only-do-not-override-1wazalj).ant-menu-light > .ant-menu {
  background: #f5f6fa;
}
:where(.css-dev-only-do-not-override-1wazalj).ant-menu .ant-menu-item,
:where(.css-dev-only-do-not-override-1wazalj).ant-menu .ant-menu-submenu,
:where(.css-dev-only-do-not-override-1wazalj).ant-menu .ant-menu-submenu-title {
  border-radius: 0;
}
ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light {
  border: none;
}

.appBarTxt {
  color: #5b7690;
  font-size: 14px;
  font-family: Arial;
  font-weight: 700;
}
.appBarSearchIpt {
  border-radius: 25px;
  background-color: #f0f0f8;
}
.appBarSearchIpt input {
  background-color: #f0f0f8;
}
.site-form-item-icon {
  color: #9e9e9e;
}
.h64p {
  height: 64px;
}

.appBarUserName {
  color: #525252;
  font-size: 14px;
  font-family: Arial;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.appBarUserType {
  color: #525252;
  font-size: 12px;
  font-family: Arial;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.appBarUserContainer {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
}
.menuHeading {
  margin: 30px 15px;
}

.menuHeadingTxt {
  color: #525252;
  font-size: 12px;
  font-family: Arial;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
}

/* width */
::-webkit-scrollbar {
  width: 3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #9e9e9e61;
}

li.ant-menu-submenu.ant-menu-submenu-inline {
  margin: 10px 0;
}

.ant-popover-inner {
  border-radius: 0 !important;
}

.ant-btn {
  border-radius: 0 !important;
}
.ant-popover-content {
  width: 200px;
}
/* Layout End */

/* dashboard start */
.dashboardCardTxt {
  color: #5b7690;
  /* Arial 14px */
  font-size: 14px;
  font-family: Arial;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.28px;
}

.dashboardCard {
  background: #fff;
  border-radius: 0;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  /* height: 400px; */
  /* overflow: auto; */
}

.dashboardCard2 > .ant-card-body {
  padding: 0;
}

:where(.css-dev-only-do-not-override-1wazalj).ant-layout {
  background-color: #fff;
}
/* dshboard end */

/* employee list start */
.pageTitle {
  color: #525252;
  /* Arial 14px */
  font-size: 14px;
  font-family: Arial;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.28px;
}
.ant-btn-primary {
  background-color: #5b7690;
}
/* employee list end */

:where(.css-dev-only-do-not-override-1wazalj).ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before,
:where(.css-dev-only-do-not-override-1wazalj).ant-table-wrapper
  .ant-table-thead
  > tr
  > td:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  display: none;
}

:where(.css-dev-only-do-not-override-1wazalj).ant-table-wrapper
  .ant-table-container
  table
  > thead
  > tr:first-child
  > *:first-child {
  border-radius: 0;
}
:where(.css-dev-only-do-not-override-1wazalj).ant-table-wrapper
  .ant-table-container
  table
  > thead
  > tr:first-child
  > *:last-child {
  border-radius: 0;
}

:where(.css-dev-only-do-not-override-1wazalj).ant-table-wrapper
  .ant-table-tbody
  > tr
  > th,
:where(.css-dev-only-do-not-override-1wazalj).ant-table-wrapper
  .ant-table-tbody
  > tr
  > td {
  border: 0;
}
.ant-pagination-item {
  border-radius: 0 !important;
}

.popupTitle {
  color: #5b7690;
  /* Arial 14px */
  font-size: 14px;
  font-family: Arial;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.28px;
}
:where(.css-dev-only-do-not-override-1wazalj).ant-input {
  background-color: #f5f6fa;
}

.myAntIptSelect2 > div {
  border-radius: 0 !important;
  margin-top: 6px !important;
  background-color: #f5f6fa !important;
}

.ant-modal-footer {
  text-align: center !important;
}

.ant-modal-footer {
  text-align: center !important;
}

.ant-modal-footer button {
  border-radius: 25px !important;
  width: 120px;
  height: 35px;
}

.ant-modal-footer > .ant-btn-default {
  background-color: #babcc8;
  color: white;
  border: none;
}

.ant-modal-footer > .ant-btn-default:hover {
  color: black;
}

.DepCard .ant-card-body {
  padding: 0;
}

.ant-input {
  background-color: #f5f6fa;
}

.potionCardTitle {
  color: #5b7690;
  /* Arial 14px */
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.28px;
}

.postionCardSubtitle {
  color: #525252;
  font-family: Arial;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.interviewBtn {
  border-radius: 25px !important;
  height: 40px;
  width: 120px;
}

.popupTitle {
  color: #5b7690;
  /* Arial 14px */
  font-size: 14px;
  font-family: Arial;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.28px;
}

.Expensecolor {
  color: #5b7690 !important;
  float: left;
}

.filtercolorbtn {
  color: #ffffff;
  float: right;
  background: #5b7690;
  width: Hug (129.07px);
  height: Hug (35px);
  top: 75px;
  left: 847px;
  padding: 10px;
  gap: 434px;
  border: #ffffff;
  margin-right: 5px;
  cursor: pointer;
}
.Expensecolorbtn {
  color: #ffffff;
  float: right;
  background: #5b7690;
  width: Hug (129.07px);
  height: Hug (35px);
  top: 75px;
  left: 847px;
  padding: 10px;
  gap: 434px;
  border: #ffffff;
  cursor: pointer;
}
.fa-regular fa-trash-can {
  width: Hug (1, 025px);
  height: Hug (347px);
  top: 337px;
  left: 279px;
  gap: 434px;
}

.fa-solid fa-pen-to-square {
  width: Hug (1, 025px);
  height: Hug (347px);
  top: 337px;
  left: 279px;
  gap: 434px;
}
.fa-regular,
.far {
  font-weight: 400;
  color: black !important;
}
.fa-solid,
.fas {
  font-weight: 400;
  color: black !important;
}

/* :where(.css-dev-only-do-not-override-1wazalj) a {
  color: black !important;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
} */

.ant-table-cell a {
  color: black !important;
}
.changecolor {
  color: red;
}

.hiredSelectedTxt {
  color: #757d8a;
  text-align: center;
  font-family: Arial;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.employeeCardTxt {
  color: #525252;
  font-size: "12px";
}

.EmpName {
  color: #000;
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.32px;
}
.EmpProfile {
  color: #000;
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.32px;
}

.EmpTabHeading {
  color: #5b7690;
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.28px;
}

.EmpMessageContent {
  color: #525252;
  text-align: justify;
  font-family: Arial;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
}

.EmpMessageContainer {
  justify-content: space-between;
  width: 100%;
  background-color: #ffffff;
  padding: 10px;
}

.ant-tabs-content-holder {
  background-color: #f5f6fa;
}

.messageHeading {
  cursor: pointer;
}

.messageHeading:hover {
  color: #5b7690;
}

.ant-drawer-body {
  padding: 10px !important;
}

.MarkTxt {
  font-size: 12px;
  margin-top: 10px;
  color: #5b7690;
  cursor: pointer;
}

.MarkTxt:hover {
  color: rgb(238, 54, 22);
}

.hiringDashboardActionIcon {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
}

.ant-image-mask:hover {
  border-radius: 50%;
}

.reviewBtn {
  border-radius: 25px !important;
  width: 75px;
  border: 1px solid #5b7690;
}

.myDataView {
  display: flex;
  justify-content: center;
}

.CardStyleDataRender {
  margin: 1rem;
  height: 100%;
  display: flex;
  /* width: 100%; */
  justify-content: center;
  align-items: center;
}
.CardStyleDataRender > div {
  width: 100%;
}

/* Jitendra New Css Start */

.card_style {
  background: #f0f1f6;
  margin-left: 10px;
  margin-right: 10px;
  width: 288px !important;
}
.set_img {
  border-radius: 50%;
  margin-bottom: 10px;
  width: 72px;
  height: 72px;
  top: 17px;
  left: 21px;
}
.client_name {
  color: #5b7690;
  font-family: Arial;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0.02em;
  text-align: left;
}
.client-designation {
  font-family: Arial;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
}
.project-name {
  font-family: Arial;
  font-size: 12px;
  font-weight: 400;
  line-height: 50px;
  letter-spacing: 0.02em;
  text-align: left;
}
.setdata {
  /* float: right !important; */
  /* width: 4.354838848114014px; */
  height: 15px;
  top: 17px;
  left: 212px;
  color: #757d8a;
  font-size: 21px;
  cursor: pointer;
  /* width: 24.354839px !important; */
}

.ant-space-vertical {
  float: right !important;
}
.setplusdata {
  float: right !important;
  cursor: pointer;
  margin: 8px;
}
.myAntIptSelect2 .ant-select-selection-item {
  display: flex !important;
}
.setplusonedata {
  display: flex !important;
  float: left !important;
}
.setplusonedata a {
  color: #757d8a !important ;
}
#setsize {
  font-size: 16px !important;
  margin: 8px;
}

#filtericon {
  padding-left: 7px;
}
.AccountInfo_color {
  float: left;
  font-family: Arial;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
}
.ant-table-cell {
  color: #525252 !important;
  font-family: Arial;
}

.Expensecolorbtn {
  font-family: Arial;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
}
.Expensecolor {
  float: left;
  font-family: Arial;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
}
/* .sameinput
{
  margin-top: 30px;
} */

.ant-btn-primary {
  margin: 10px;
}

/* .sizebtn
{
  margin-top: 34px;

} */
.add-more-button,
.remove-button {
  margin-top: 8px;
  margin-top: 34px;
  font-size: 12px;
}
.remove-size {
  width: 4% !important;
}
.list-style i {
  margin: 5px;
  margin-bottom: 10px !important;
}
.ant-upload {
  padding: 10px !important;
  /* width: 165px; */
}
.ant-upload-select {
  width: 135px !important;
}

#deleteicon {
  cursor: pointer;
  font-size: 16px;
  margin-left: 10px;
}
.colorname {
  color: black;
  font-size: 20px;
}

/* Jitendra New Css End */

.search-result-item {
  display: flex;
  align-items: center;
}

.set_img1 {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 8px;
}

.colorchange {
  color: #525252;
  margin-left: 30px;
  font-size: 15px;
}
.fontchange {
  margin-top: 10px;
}

.set_img2 {
  width: 84px;
  height: 84px;
  border-radius: 50%;
  margin-right: 8px;
  margin-left: 151px;
}

.setcolor {
  color: #5b7690;
}
.addmargin {
  margin-bottom: 20px;
}
.colorchange {
  color: #525252; /* Change the color for better visibility */
}

/* CSS for the name and department container */
.colorchangeblue {
  color: #5b7690; /* Change the color for better visibility */
  margin-top: 18px;
}

/* CSS for the department */
.department {
  color: #525252; /* Change the color for better visibility */
  margin-left: 15px;
}
.container {
  width: 400px;
  margin: 0 auto;
  margin-top: -20px;
}

.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.label {
  width: 160px;
}

.value {
  width: 200px;
  word-wrap: break-word;
}
.custom-icon {
  /* Your custom styling here */
  /* For example: */
  font-size: 10px;
  margin: 5px;
}

/* Jitendra New Css End */

/* Walnut start */
.ant-menu .ant-menu-item-selected {
  background-color: #2b3e50;
  color: white;
}
.sidebarDiv {
  border-radius: 0;
}

.sidebarDiv > .ant-card-body {
  padding: 10px;
}

.ant-menu-submenu-title {
  color: #000 !important;
}

.ant-pagination-item {
  border-color: #2b3e50 !important;
}

.ant-pagination-item > a {
  color: #2b3e50 !important;
}

.selectBackColor > .ant-select-selector {
  background-color: #f5f6fa !important;
}

.sidebarIcon {
  font-size: 15px;
  margin: 10px 10px 0px;
}

.TopMenuButton {
  width: 177px;
  background: #5b7690;
  color: #fff;
}

.TopMenuButton:hover {
  color: #5b7690 !important;
  border: 1px solid #5b7690 !important;
  background: #fff;
}

:where(.css-dev-only-do-not-override-txh9fw).ant-btn-default:disabled:hover {
  cursor: not-allowed;
  border-color: #d9d9d9 !important;
  color: rgba(0, 0, 0, 0.25) !important;
  background-color: rgba(0, 0, 0, 0.04) !important;
  box-shadow: none;
}

.TopMenuTxt {
  color: #000;
  /* font-family: Montserrat; */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.28px;
}

.lineModalCol {
  text-align: center;
}

.lineModalButton {
  display: flex;
  width: 192px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 25px !important;
  border: 1px solid #606060;
}

.lineModalButtonActive {
  background-color: #c9d5e3;
  border: 1px solid #c9d5e3;
}
.lineModalButton:hover {
  background-color: #606060;
  border: 1px solid #fff !important;
  color: #fff !important;
}
.lineModalButtonSUbmit {
  display: flex;
  width: 131px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 25px !important;
  background: #2b3e50 !important;
  color: #fff !important;
}

.lineModalButtonSUbmit2 {
  display: flex;
  width: 131px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 25px !important;
  background: #babcc8 !important;
  color: #fff !important;
}

.lineModalButtonSUbmit:hover {
  background: #fff !important;
  color: #2b3e50 !important;
  border: 1px solid #2b3e50 !important;
}

.topLogoutBtn:hover {
  background-color: #c9d5e3 !important;
  color: #000 !important;
}

.masterCartonAddButton {
  display: flex;
  width: 325px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: #5b7690;
  color: #fff;
}

.masterCartonAddButton:hover {
  color: #5b7690 !important;
  border: 1px solid #5b7690 !important;
  background: #fff;
}

.masterCartonAddInput {
  display: flex;
  width: 325px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 164px;
  border-radius: 0;
}
.masterCartonAddSelect {
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  /* gap: 164px; */
  border-radius: 0;
}

.masterCartonAddInput:hover {
  border: 1px solid #5b7690 !important;
}

.masterCartonAddInput:focus {
  border: 1px solid #5b7690 !important;
}

/* walnut end */

/* Jitendra New Css Start */

.card_style {
  background: #f0f1f6;
  margin-left: 10px;
  margin-right: 10px;
  width: 288px !important;
}
.set_img {
  border-radius: 50%;
  margin-bottom: 10px;
  width: 72px;
  height: 72px;
  top: 17px;
  left: 21px;
}
.client_name {
  color: #5b7690;
  font-family: Arial;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0.02em;
  text-align: left;
}
.client-designation {
  font-family: Arial;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
}
.project-name {
  font-family: Arial;
  font-size: 12px;
  font-weight: 400;
  line-height: 50px;
  letter-spacing: 0.02em;
  text-align: left;
}
.setdata {
  /* float: right !important; */
  /* width: 4.354838848114014px; */
  height: 15px;
  top: 17px;
  left: 212px;
  color: #757d8a;
  font-size: 21px;
  cursor: pointer;
  /* width: 24.354839px !important; */
}

.ant-space-vertical {
  float: right !important;
}
.setplusdata {
  float: right !important;
  cursor: pointer;
  margin: 8px;
}
.myAntIptSelect2 .ant-select-selection-item {
  display: flex !important;
}
.setplusonedata {
  display: flex !important;
  float: left !important;
}
.setplusonedata a {
  color: #757d8a !important ;
}
#setsize {
  font-size: 16px !important;
  margin: 8px;
}

#filtericon {
  padding-left: 7px;
}
.AccountInfo_color {
  float: left;
  font-family: Arial;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
}
.ant-table-cell {
  color: #525252 !important;
  font-family: Arial;
}

.Expensecolorbtn {
  font-family: Arial;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
}
.Expensecolor {
  float: left;
  font-family: Arial;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
}
/* .sameinput
{
  margin-top: 30px;
} */

.ant-btn-primary {
  margin: 10px;
}

/* .sizebtn
{
  margin-top: 34px;

} */
.add-more-button,
.remove-button {
  margin-top: 8px;
  margin-top: 34px;
  font-size: 12px;
}
.remove-size {
  width: 4% !important;
}
.list-style i {
  margin: 5px;
  margin-bottom: 10px !important;
}
.ant-upload {
  padding: 10px !important;
  /* width: 165px; */
}
.ant-upload-select {
  width: 135px !important;
}

#deleteicon {
  cursor: pointer;
  font-size: 16px;
  margin-left: 10px;
}
.colorname {
  color: black;
  font-size: 20px;
}
.circular-buttonsave {
  width: 118px !important;
  height: 35px !important;
  padding: 5px !important;
  border-radius: 25px !important;
  gap: 10px !important;
  background: #5b7690;
}
.circular-button {
  width: 156px !important;
  height: 35px;
  gap: 20px;
  border-radius: 25px !important;
  background: #5b7690;
  color: #fff;
}
.btn-save {
  width: 31px;
  height: 15px;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #ffffff;
}
.btn-savedelete {
  width: 118px !important;
  height: 35px !important;
  padding: 5px !important;
  border-radius: 25px !important;
  gap: 10px !important;
  background: #babcc8 !important;
}
/* Jitendra New Css Start */

.card_style {
  background: #f0f1f6;
  margin-left: 10px;
  margin-right: 10px;
  width: 288px !important;
}
.set_img {
  border-radius: 50%;
  margin-bottom: 10px;
  width: 72px;
  height: 72px;
  top: 17px;
  left: 21px;
}
.client_name {
  color: #5b7690;
  font-family: Arial;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0.02em;
  text-align: left;
}
.client-designation {
  font-family: Arial;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
}
.project-name {
  font-family: Arial;
  font-size: 12px;
  font-weight: 400;
  line-height: 50px;
  letter-spacing: 0.02em;
  text-align: left;
}
.setdata {
  /* float: right !important; */
  /* width: 4.354838848114014px; */
  height: 15px;
  top: 17px;
  left: 212px;
  color: #757d8a;
  font-size: 21px;
  cursor: pointer;
  /* width: 24.354839px !important; */
}

.ant-space-vertical {
  float: right !important;
}
.setplusdata {
  float: right !important;
  cursor: pointer;
  margin: 8px;
}
.myAntIptSelect2 .ant-select-selection-item {
  display: flex !important;
}
.setplusonedata {
  display: flex !important;
  float: left !important;
}
.setplusonedata a {
  color: #757d8a !important ;
}
#setsize {
  font-size: 16px !important;
  margin: 8px;
}

#filtericon {
  padding-left: 7px;
}
.AccountInfo_color {
  float: left;
  font-family: Arial;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
}
.ant-table-cell {
  color: #525252 !important;
  font-family: Arial;
}

.Expensecolorbtn {
  font-family: Arial;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
}
.Expensecolor {
  float: left;
  font-family: Arial;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
}
/* .sameinput
{
  margin-top: 30px;
} */

.ant-btn-primary {
  margin: 10px;
}

/* .sizebtn
{
  margin-top: 34px;

} */
.add-more-button,
.remove-button {
  margin-top: 8px;
  margin-top: 34px;
  font-size: 12px;
}
.remove-size {
  width: 4% !important;
}
.list-style i {
  margin: 5px;
  margin-bottom: 10px !important;
}
.ant-upload {
  padding: 10px !important;
  /* width: 165px; */
}
.ant-upload-select {
  width: 135px !important;
}

#deleteicon {
  cursor: pointer;
  font-size: 16px;
  margin-left: 10px;
}
.colorname {
  color: black;
  font-size: 20px;
}

/* Jitendra New Css End */

.search-result-item {
  display: flex;
  align-items: center;
}

.set_img1 {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 8px;
}

.colorchange {
  color: #525252;
  margin-left: 30px;
  font-size: 15px;
}
.fontchange {
  margin-top: 10px;
}

.set_img2 {
  width: 84px;
  height: 84px;
  border-radius: 50%;
  margin-right: 8px;
  margin-left: 151px;
}

.setcolor {
  color: #5b7690;
}
.addmargin {
  margin-bottom: 20px;
}
.colorchange {
  color: #525252; /* Change the color for better visibility */
}

/* CSS for the name and department container */
.colorchangeblue {
  color: #5b7690; /* Change the color for better visibility */
  margin-top: 18px;
}

/* CSS for the department */
.department {
  color: #525252; /* Change the color for better visibility */
  margin-left: 15px;
}
.container {
  width: 400px;
  margin: 0 auto;
  margin-top: -20px;
}

.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.label {
  width: 160px;
}

.value {
  width: 200px;
  word-wrap: break-word;
}
.custom-icon {
  /* Your custom styling here */
  /* For example: */
  font-size: 10px;
  margin: 5px;
}

/* Jitendra New Css End */

/* Walnut start */
.ant-menu .ant-menu-item-selected {
  background-color: #2b3e50;
  color: white;
}
.sidebarDiv {
  border-radius: 0;
}

.sidebarDiv > .ant-card-body {
  padding: 10px;
}

.ant-menu-submenu-title {
  color: #000 !important;
}

.ant-pagination-item {
  border-color: #2b3e50 !important;
}

.ant-pagination-item > a {
  color: #2b3e50 !important;
}

.selectBackColor > .ant-select-selector {
  background-color: #f5f6fa !important;
}

.sidebarIcon {
  font-size: 15px;
  margin: 10px 10px 0px;
}

.TopMenuButton {
  width: 177px;
  background: #5b7690;
  color: #fff;
}

.TopMenuButton:hover {
  color: #5b7690 !important;
  border: 1px solid #5b7690 !important;
  background: #fff;
}

:where(.css-dev-only-do-not-override-txh9fw).ant-btn-default:disabled:hover {
  cursor: not-allowed;
  border-color: #d9d9d9 !important;
  color: rgba(0, 0, 0, 0.25) !important;
  background-color: rgba(0, 0, 0, 0.04) !important;
  box-shadow: none;
}

.TopMenuTxt {
  color: #000;
  /* font-family: Montserrat; */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.28px;
}

.lineModalCol {
  text-align: center;
}

.lineModalButton {
  display: flex;
  width: 192px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 25px !important;
  border: 1px solid #606060;
}

.lineModalButtonActive {
  background-color: #c9d5e3;
  border: 1px solid #c9d5e3;
}
.lineModalButton:hover {
  background-color: #606060;
  border: 1px solid #fff !important;
  color: #fff !important;
}
.lineModalButtonSUbmit {
  display: flex;
  width: 131px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 25px !important;
  background: #2b3e50 !important;
  color: #fff !important;
}

.lineModalButtonSUbmit2 {
  display: flex;
  width: 131px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 25px !important;
  background: #babcc8 !important;
  color: #fff !important;
}

.lineModalButtonSUbmit:hover {
  background: #fff !important;
  color: #2b3e50 !important;
  border: 1px solid #2b3e50 !important;
}

.topLogoutBtn:hover {
  background-color: #c9d5e3 !important;
  color: #000 !important;
}

.masterCartonAddButton {
  display: flex;
  width: 325px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: #5b7690;
  color: #fff;
}

.masterCartonAddButton:hover {
  color: #5b7690 !important;
  border: 1px solid #5b7690 !important;
  background: #fff;
}

.masterCartonAddInput {
  display: flex;
  width: 325px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 164px;
  border-radius: 0;
}

.masterCartonAddInput:hover {
  border: 1px solid #5b7690 !important;
}

.masterCartonAddInput:focus {
  border: 1px solid #5b7690 !important;
}

/* walnut end */

/* Jitendra New Css Start */

.card_style {
  background: #f0f1f6;
  margin-left: 10px;
  margin-right: 10px;
  width: 288px !important;
}
.set_img {
  border-radius: 50%;
  margin-bottom: 10px;
  width: 72px;
  height: 72px;
  top: 17px;
  left: 21px;
}
.client_name {
  color: #5b7690;
  font-family: Arial;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0.02em;
  text-align: left;
}
.client-designation {
  font-family: Arial;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
}
.project-name {
  font-family: Arial;
  font-size: 12px;
  font-weight: 400;
  line-height: 50px;
  letter-spacing: 0.02em;
  text-align: left;
}
.setdata {
  /* float: right !important; */
  /* width: 4.354838848114014px; */
  height: 15px;
  top: 17px;
  left: 212px;
  color: #757d8a;
  font-size: 21px;
  cursor: pointer;
  /* width: 24.354839px !important; */
}

.ant-space-vertical {
  float: right !important;
}
.setplusdata {
  float: right !important;
  cursor: pointer;
  margin: 8px;
}
.myAntIptSelect2 .ant-select-selection-item {
  display: flex !important;
}
.setplusonedata {
  display: flex !important;
  float: left !important;
}
.setplusonedata a {
  color: #757d8a !important ;
}
#setsize {
  font-size: 16px !important;
  margin: 8px;
}

#filtericon {
  padding-left: 7px;
}
.AccountInfo_color {
  float: left;
  font-family: Arial;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
}
.ant-table-cell {
  color: #525252 !important;
  font-family: Arial;
}

.Expensecolorbtn {
  font-family: Arial;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
}
.Expensecolor {
  float: left;
  font-family: Arial;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
}
/* .sameinput
{
  margin-top: 30px;
} */

.ant-btn-primary {
  margin: 10px;
}

/* .sizebtn
{
  margin-top: 34px;

} */
.add-more-button,
.remove-button {
  margin-top: 8px;
  margin-top: 34px;
  font-size: 12px;
}
.remove-size {
  width: 4% !important;
}
.list-style i {
  margin: 5px;
  margin-bottom: 10px !important;
}
.ant-upload {
  padding: 10px !important;
  /* width: 165px; */
}
.ant-upload-select {
  width: 135px !important;
}

#deleteicon {
  cursor: pointer;
  font-size: 16px;
  margin-left: 10px;
}
.colorname {
  color: black;
  font-size: 20px;
}
.circular-buttonsave {
  width: 118px !important;
  height: 35px !important;
  padding: 5px !important;
  border-radius: 25px !important;
  gap: 10px !important;
  background: #5b7690;
}
.circular-button {
  width: 156px !important;
  height: 35px;
  gap: 20px;
  border-radius: 25px !important;
  background: #5b7690;
  color: #fff;
}
.btn-save {
  width: 31px;
  height: 15px;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #ffffff;
}
.btn-savedelete {
  width: 118px !important;
  height: 35px !important;
  padding: 5px !important;
  border-radius: 25px !important;
  gap: 10px !important;
  background: #babcc8 !important;
}

.ButtonSUbmit {
  display: flex;
  width: 131px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 25px !important;
  background: #5b7690 !important;
  color: #fff !important;
}
/* Jitendra New Css End */

.search-result-item {
  display: flex;
  align-items: center;
}

.set_img1 {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 8px;
}

.colorchange {
  color: #525252;
  margin-left: 30px;
  font-size: 15px;
}
.fontchange {
  margin-top: 10px;
}

.set_img2 {
  width: 84px;
  height: 84px;
  border-radius: 50%;
  margin-right: 8px;
  margin-left: 151px;
}

.setcolor {
  color: #5b7690;
}
.addmargin {
  margin-bottom: 20px;
}
.colorchange {
  color: #525252; /* Change the color for better visibility */
}

/* CSS for the name and department container */
.colorchangeblue {
  color: #5b7690; /* Change the color for better visibility */
  margin-top: 18px;
}

/* CSS for the department */
.department {
  color: #525252; /* Change the color for better visibility */
  margin-left: 15px;
}
.container {
  width: 400px;
  margin: 0 auto;
  margin-top: -20px;
}

.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.label {
  width: 160px;
}

.value {
  width: 200px;
  word-wrap: break-word;
}
.custom-icon {
  /* Your custom styling here */
  /* For example: */
  font-size: 10px;
  margin: 5px;
}
.TopMenuButton1 {
  width: 170px;
  background: #ffffff;
  border: 1px solid #ff5c5c;
  color: #ff5c5c;
  border-radius: 25px !important;
  margin: 5px;
}

.TopMenuButton2 {
  width: 170px;
  background: #ffffff;
  color: #606060;
  border-radius: 25px !important;
  margin: 5px;
}

.TopMenuButton3 button {
  width: 170px;
  background: #ffffff;
  color: #606060;
  border-radius: 25px !important;
  margin: 5px;
}
.cancle {
  background-color: #babcc8;
}
.checkbox-icon {
  width: 20px; /* Adjust the width as needed */
  height: 20px; /* Adjust the height as needed */
  display: inline-block;
}

.checked {
  color: blue;
  border: 1px solid blue;
}

.unchecked {
  color: black;
  border: 1px solid black;
}

.checkbox-icon .anticon {
  font-size: 20px;
}

.not-ok-row {
  background-color: #ffe7e7;
  color: white;
}

/* .not-ok-row:hover {
  background-color: #ffe7e7 !important;
  color: white !important;
} */

.not-ok-row2 {
  background-color: #ffe7e7;
  color: white;
}

/* .not-ok-row2:hover {
  background-color: #ffe7e7 !important;
  color: white !important;
} */

.filter-modal {
  width: 25% !important;
  height: 220px !important;
  float: right !important;
  right: 20px !important;
  top: 150px;
}
.circular-button1 {
  width: 110px !important;
  height: 35px;
  gap: 20px;
  border-radius: 25px !important;
  background: #5b7690;
  color: #fff;
}
filter-modal1 {
  height: 220px !important;
  float: right !important;
  right: 20px !important;
  top: 150px;
}

/* Jitendra New Css End */

.search-result-item {
  display: flex;
  align-items: center;
}

.set_img1 {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 8px;
}

.colorchange {
  color: #525252;
  margin-left: 30px;
  font-size: 15px;
}
.fontchange {
  margin-top: 10px;
}

.set_img2 {
  width: 84px;
  height: 84px;
  border-radius: 50%;
  margin-right: 8px;
  margin-left: 151px;
}

.setcolor {
  color: #5b7690;
}
.addmargin {
  margin-bottom: 20px;
}
.colorchange {
  color: #525252; /* Change the color for better visibility */
}

/* CSS for the name and department container */
.colorchangeblue {
  color: #5b7690; /* Change the color for better visibility */
  margin-top: 18px;
}

/* CSS for the department */
.department {
  color: #525252; /* Change the color for better visibility */
  margin-left: 15px;
}
.container {
  width: 400px;
  margin: 0 auto;
  margin-top: -20px;
}

.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.label {
  width: 160px;
}

.value {
  width: 200px;
  word-wrap: break-word;
}
.custom-icon {
  /* Your custom styling here */
  /* For example: */
  font-size: 10px;
  margin: 5px;
}

/* Jitendra New Css End */

/* Walnut start */
.ant-menu .ant-menu-item-selected {
  background-color: #2b3e50;
  color: white;
}
.sidebarDiv {
  border-radius: 0;
}

.sidebarDiv > .ant-card-body {
  padding: 10px;
}

.ant-menu-submenu-title {
  color: #000 !important;
}

.ant-pagination-item {
  border-color: #2b3e50 !important;
}

.ant-pagination-item > a {
  color: #2b3e50 !important;
}

.selectBackColor > .ant-select-selector {
  background-color: #f5f6fa !important;
}

.sidebarIcon {
  font-size: 15px;
  margin: 10px 10px 0px;
}

.TopMenuButton {
  width: 177px;
  background: #5b7690;
  color: #fff;
}

.TopMenuButton:hover {
  color: #5b7690 !important;
  border: 1px solid #5b7690 !important;
  background: #fff;
}

:where(.css-dev-only-do-not-override-txh9fw).ant-btn-default:disabled:hover {
  cursor: not-allowed;
  border-color: #d9d9d9 !important;
  color: rgba(0, 0, 0, 0.25) !important;
  background-color: rgba(0, 0, 0, 0.04) !important;
  box-shadow: none;
}

.TopMenuTxt {
  color: #000;
  /* font-family: Montserrat; */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.28px;
}

.lineModalCol {
  text-align: center;
}

.lineModalButton {
  display: flex;
  width: 192px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 25px !important;
  border: 1px solid #606060;
}

.lineModalButtonActive {
  background-color: #c9d5e3;
  border: 1px solid #c9d5e3;
}
.lineModalButton:hover {
  background-color: #606060;
  border: 1px solid #fff !important;
  color: #fff !important;
}
.lineModalButtonSUbmit {
  display: flex;
  width: 131px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 25px !important;
  background: #2b3e50 !important;
  color: #fff !important;
}

.lineModalButtonSUbmit2 {
  display: flex;
  width: 131px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 25px !important;
  background: #babcc8 !important;
  color: #fff !important;
}

.lineModalButtonSUbmit:hover {
  background: #fff !important;
  color: #2b3e50 !important;
  border: 1px solid #2b3e50 !important;
}

.topLogoutBtn:hover {
  background-color: #c9d5e3 !important;
  color: #000 !important;
}

.masterCartonAddButton {
  display: flex;
  width: 325px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: #5b7690;
  color: #fff;
}

.masterCartonAddButton:hover {
  color: #5b7690 !important;
  border: 1px solid #5b7690 !important;
  background: #fff;
}

.masterCartonAddInput {
  display: flex;
  width: 325px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 164px;
  border-radius: 0;
}

.masterCartonAddInput:hover {
  border: 1px solid #5b7690 !important;
}

.masterCartonAddInput:focus {
  border: 1px solid #5b7690 !important;
}

/* walnut end */

/* Responsive styles for smaller screens */
@media screen and (max-width: 768px) {
  .col-left {
    display: flex;
  }

  .loginTitle {
    font-size: 20px;
  }

  .loginFormContainer {
    padding: 3rem;
    padding-top: 150px !important;
  }

  .myAntIpt {
    padding: 8px !important;
  }

  .myAntIpt2 {
    padding: 4px 8px !important;
  }

  .myAntLoginBtn {
    height: 30px !important;
  }

  .OTPInputContainer input {
    height: 40px;
    width: 40px !important;
    margin: 20px;
  }
}
