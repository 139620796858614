.otp-input-container {
    display: flex;
    justify-content: center;
}

.otp-input {
    text-align: center;
    font-size: 1rem;
    border-radius: 0.375rem;
}

.error {
    border-color: #f87171;
}

.success {
    border-color: #34d399;
}